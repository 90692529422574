<template>
  <backoffice-container>
    <b-card>
      <div class="d-flex flex-wrap justify-content-between mt-2">
        <div class="d-flex flex-wrap">
          <div>
            <h4>{{ $t("backoffice.types.manage-types") }}</h4>
            <p>{{ $t("backoffice.types.note") }}</p>
          </div>
        </div>
        <b-button
          variant="primary"
          type="submit"
          class="align-self-start"
          @click="openModal()"
        >
          {{ $t('backoffice.types.create-type') }}
        </b-button>
      </div>
      <div v-if="isLoading" class="mb-5">
        <b-spinner
          type="grow"
          small
          variant="primary"
          class="mx-auto mt-3 d-block"
        />
      </div>
      <div v-else>
        <b-row v-if="enableTypes && enableTypes.length > 0" class="mt-1">
          <b-col
            v-for="(model, index) in enableTypes"
            :key="index"
            cols="12"
            md="6"
            lg="4"
            xl="3"
            class="mb-2"
          >
            <b-card
              header-bg-variant="light"
              style="height: 260px"
            >
              <template #header>
                <div class="d-flex flex-wrap justify-content-between">
                  <div class="d-flex flex-wrap" />
                  <h4> {{ getModelName(model) }}</h4>
                </div>
                <b-button variant="link" class="p-0 mb-25" @click=" openModal(model)">
                  <feather-icon icon="PlusIcon" variant="primary" size="20" />
                </b-button>
              </template>
              <b-card-text class="y-scroll-types-container mt-1">
                <div
                  v-for="(type, i) in types"
                  v-show="types.length>0"
                  :key="type.id"
                >
                  <template v-if="isThisModelType(type.modelType, model )">
                    <div class="d-flex flex-wrap justify-content-between">
                      <p class="font-weight-bold mb-25 text-capitalize">
                        {{ type.namePlural[locale] || Object.values(type.namePlural)[0] }}
                      </p>
                      <small v-if="type.isGlobal"><b-badge pill variant="primary" class="align-self-start ml-50 pb-25">Global</b-badge></small>
                      <div v-if="!type.isGlobal" class="d-flex">
                        <feather-icon
                          icon="Edit2Icon"
                          size="16"
                          class="text-primary d-block ml-2"
                          style="cursor: pointer"
                          @click="handleEdit(type)"
                        />
                        <!-- Unshare -->
                        <feather-icon
                          icon="Trash2Icon"
                          :title="$t('unshare.tooltip')"
                          size="16"
                          class="text-primary pointer ml-1"
                          @click="handleRemove(type)"
                        />
                      </div>
                    </div>
                    <hr class="mt-0"></hr>
                  </template>
                </div>
              </b-card-text>
            </b-card>
          </b-col>
        </b-row>
        <!-- Container without data: Placeholder -->
        <b-row v-else class="horizontal-placeholder">
          <b-col v-if="placeholder" cols="12">
            <img :src="placeholder">
          </b-col>
          <b-col cols="12">
            <p class="text-primary">
              {{ $t('available.message', { itemName: $t('backoffice.types.title') }) }}
            </p>
          </b-col>
        </b-row>
        <!-- <div v-if="isLoadingNextPage" class="mb-5">
          <b-spinner
            type="grow"
            small
            variant="primary"
            class="mx-auto mt-3 d-block"
          />
        </div> -->
        <!-- <b-button
          v-if="hasMore"
          class="center-x my-3"
          variant="outline-primary"
          @click="getMoreCommunities"
        >
          {{ $t('action.load-more') }}
        </b-button> -->
      </div>
    </b-card>
    <b-modal
      id="create-modal"
      :ok-title="$t('form.actions.save')"
      no-close-on-backdrop
      ok-only
      :ok-disabled="isDisabled"
      @ok="handleCreate(model=null)"
      @hidden="reset"
    >
      <template #modal-header>
        <language-selector-header :title="isEditing ? $t('backoffice.types.edit-type') : $t('backoffice.types.create-type')" @closeModal="closeModal" @selectLanguage="(language)=>selectedLanguage = language" />
      </template>
      <label class="font-weight-bold" label-for="name">{{ $t('backoffice.settings.community-name') }}*</label>
      <b-form-input
        v-model="createForm.name[selectedLanguage]"
        class="mt-50"
        :value-placeholder="$t('layouts.widget-name')"
        label-for="name"
        value-attribute="text"
      />

      <label class="font-weight-bold mt-1" label-for="plural">{{ $t('backoffice.types.plural-name') }}*</label>
      <b-form-input
        v-model="createForm.plural[selectedLanguage]"
        class="mt-50"
        :value-placeholder="$t('layouts.widget-name')"
        label-for="name"
        value-attribute="text"
      />
      <label class="font-weight-bold mt-1" label-for="description">{{ $t('backoffice.types.description') }}*</label>
      <b-form-textarea
        v-model="createForm.description[selectedLanguage]"
        class="mt-50"
        :value-placeholder="$t('layouts.widget-name')"
        label-for="name"
        value-attribute="text"
      />
      <b-form-group>
        <label for="rol-name" class="font-weight-bold mt-1"> {{ $tc('backoffice.classifiers.model', 0) }}*</label>
        <div v-if="isEditing" class="d-flex flex-wrap">
          <p
            v-for="(type, index) in createForm.type"
            :key="index"
            class="text-capitalize"
            :class="{'ml-25' : index != 0}"
          >
            {{ getModelName(type) }}<span v-if="index != createForm.type.length -1">,</span>
          </p>
        </div>
        <v-select
          v-else
          v-model="createForm.type"
          class="select-control p-0"
          :placeholder="$t('form.select-multiple-placeholder')"
          clearable
          :multiple="true"
          :label="locale"
          :get-option-label="(model) => getModelName(model)"
          :options="enableTypes"
        />
      </b-form-group>
    </b-modal>
    <unshare-modal
      v-model="isUnshareModalVisible"
      model-type="type"
      @remove-item="handleRemoveItem"
    />
  </backoffice-container>
</template>

<script>
import ListContainer from '@core/constants/ListContainer';
import { TypeMorph, TypeMorphTranslated } from '@copernicsw/community-common';
import BackofficeContainer from '@/backoffice/components/BackofficeContainer.vue';
import vSelect from 'vue-select';
import Placeholder from '@/assets/images/placeholders/light/general-placeholder.svg';
import UnshareModal from '@/@core/components/modal/UnshareModal.vue';
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import FormTranslationTable from '@core/components/form-translation-table/FormTranslationTable.vue';
import LanguageSelectorHeader from '@core/components/modal/LanguageSelectorHeader.vue';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';

export default {
  name: 'Types',
  components: {
    BackofficeContainer,
    vSelect,
    UnshareModal,
    FormTranslationTable,
    LanguageSelectorHeader,

  },
  mixins: [ToastNotificationsMixin],
  data() {
    return {
      createForm: {
        type: null,
        name: {},
        plural: {},
        description: {},
      },
      isContactModalVisible: false,
      isLoading: false,
      isLoadingNextPage: false,
      perPage: 20,
      lastLoadedPage: 1,
      communities: [],
      currentPage: 1,
      total: null,
      isUnshareModalVisible: false,
      currentItem: '',
      isEditing: false,
      selectedLanguage: this.currentLocale,
    };
  },
  computed: {
    isDisabled() {
      return this.createForm.name.length === 0 || this.createForm.plural.length === 0 || !this.createForm.type || this.createForm.type.length === 0 || this.createForm.description.length === 0;
    },
    placeholder() {
      return Placeholder;
    },
    locale() {
      return this.$store.state.locale.currentLocale;
    },
    types() {
      return this.$store.getters.types?.entities?.unpaginated.reverse();
    },
    modelType() {
      const modelType = Object.values(TypeMorph);
      modelType.push('challenge');
      return modelType;
      // return [...new Set(this.$store.getters.types?.entities?.unpaginated.map((item) => item.modelType))];
    },
    enabledApps() {
      return this.$store.getters.installedApps?.data;
    },
    gridContainer() {
      return ListContainer.Grid;
    },
    currentMember() {
      return this.$store.getters.loggedUser.key;
    },
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
    enableTypes() {
      const model = [];
      this.modelType.filter((item) => {
        this.enabledApps?.map((i) => {
          if (typeof i.addonSingular === 'string') {
            item === i.addonSingular ? model.push(item) : '';
          } else {
            item === i.key ? model.push(item) : '';
          }
        });
      });
      console.log(model);
      return model;
    },
  },
  watch: {
    async currentMember(value) {
      await this.fetchData(this.page);
      return value;
    },
    communities(value) {
      return value;
    },
  },
  async created() {
    await this.getTypes();
    await this.getInstalledApps();
  },
  methods: {
    translate(field) {
      return translateTranslationTable(this.selectedLanguage, field);
    },
    async getInstalledApps() {
      await this.$store.dispatch('getInstalledApps');
    },
    getModelName(model) {
      if (model) {
        TypeMorphTranslated.challenge = { es: 'Retos', en: 'Challenges', ca: 'Reptes' };
        console.log(TypeMorphTranslated);
        return TypeMorphTranslated[model][this.locale] || Object.values(TypeMorphTranslated[model])[0];
      }
    },
    async getTypes() {
      this.isLoading = true;

      await this.$store.dispatch('getItems', {
        itemType: 'types',
        storedKey: 'entities',
        forceAPICall: true,
        requestConfig: {
          getAll: 1,
          getCommunityTypes: 1,
          count: 1000,
        },
      });
      this.isLoading = false;
    },

    openModal(model) {
      this.isEditing = false;
      this.$bvModal.show('create-modal');
      this.createForm.type = model ? [model] : null;
    },
    async handleCreate() {
      if (this.isEditing) {
        try {
          await this.$store.dispatch('editItem', {
            // forceUpdate: true,
            item: {
              itemType: 'types',
              storedKey: 'entities',
              requestConfig: {
                typeKey: this.currentItem.key,
                name: this.createForm.name,
                namePlural: this.createForm.plural,
                description: this.createForm.description,
                modelType: this.createForm.type,
              },
            },
          });
          this.notifySuccess(this.$t('backoffice.types.messages.edit-success'));
          this.getTypes();
        } catch {
          this.notifyError(this.$t('backoffice.types.messages.edit-error'));
        }
      } else {
        try {
          await this.$store.dispatch('createItem', {
            noSet: true,
            item: {
              itemType: 'types',
              storedKey: 'entities',
              requestConfig: {
                name: this.createForm.name,
                namePlural: this.createForm.plural,
                description: this.createForm.description,
                modelType: this.createForm.type,
              },
            },
          });
          this.notifySuccess(this.$t('backoffice.types.messages.create-success'));
          this.getTypes();
        } catch {
          this.notifyError(this.$t('backoffice.types.messages.create-error'));
        }
      }
    },
    reset() {
      this.createForm = {
        type: null,
        name: {},
        description: {},
        plural: {},
      };
    },
    handleRemove(type) {
      this.isUnshareModalVisible = true;
      this.currentItem = type;
    },
    async handleRemoveItem(response) {
      if (response === true) {
        try {
          await this.$store.dispatch('postItem', {
            type: 'types/delete',
            customName: 'types',
            storedKey: 'entities',
            forceUpdate: true,
            requestConfig: {
              typeKey: this.currentItem.key,
            },
          });
          this.notifySuccess(this.$t('backoffice.types.messages.remove-success'));
          this.getTypes();
        } catch {
          this.notifyError(this.$t('backoffice.types.messages.remove-error'));
        }
      }
    },
    handleEdit(type) {
      this.isEditing = true;
      this.createForm = {
        type: type.modelType,
        name: type.name,
        plural: type.namePlural,
        description: type.description,
      };
      this.currentItem = type;
      this.$bvModal.show('create-modal');
    },
    isThisModelType(modelType, model) {
      if (Array.isArray(modelType)) {
        return modelType.includes(model);
      }
      return modelType === model;
    },
    closeModal() {
      this.$bvModal.hide('create-modal');
    },
  },
};
</script>
